<template>
	<page-container :page="page">
		<main-content
			:title="page.title || page.header"
			:color="page.color"
			:content="page.content"
			:heading-image="page.headingImages && page.headingImages[0]"
		>
			<template #content>
				<div class="row">
					<div class="columns column12 align-center">
						<h1>{{ page.title }}</h1>
						<div v-parse-links v-html="page.content" />
					</div>
				</div>
			</template>
		</main-content>

		<section class="main-content image-section">
			<div class="row rellax">
				<div v-for="image in defaults[locale].website.images" :key="image.ID">
					<picture>
						<source :srcset="image.featureWebp" type="image/webp" />
						<source :srcset="image.feature" />
						<img :src="image.feature" :alt="image.featureimageAlt" loading="lazy" class="fade-image" />
					</picture>
				</div>
			</div>
		</section>
	</page-container>
</template>

<script setup>
import Rellax from 'rellax';

const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

onMounted(() => {
	const elems = document.querySelectorAll('.rellax > div');

	let speed = 1;
	elems.forEach((elem) => {
		new Rellax(elem, {
			speed,
		});
		speed--;
	});
});
</script>

<style lang="scss" scoped>
.row {
	max-width: 1220px;
}

.image-section {
	margin: 100px 0 300px;

	.row {
		display: flex;
		flex-flow: row nowrap;
		padding-left: 30px;
		overflow: visible;
		position: relative;

		div {
			margin: 0 -30px 0 0;
			z-index: 2;
			width: 100%;

			&:first-child {
				margin: -30px -60px 0 0;
				z-index: 3;
			}

			&:last-child {
				margin: 30px 30px 0 -30px;
				z-index: 1;
			}
		}
	}
}

@media (max-width: 900px) {
	.image-section {
		margin: 50px 0 150px;

		.row {
			div {
				margin: 0 -30px 0 0;
				z-index: 2;

				&:first-child {
					margin: 0 -60px 0 0;
					z-index: 3;
				}

				&:last-child {
					margin: -0 30px 0 -30px;
					z-index: 1;
				}
			}
		}
	}
}
</style>
